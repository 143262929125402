<script setup lang="ts">
import type { InitDataBanner } from "@/types";

const props = defineProps<{ banner: InitDataBanner }>();

const { t } = useT();
const { data: appInit } = useAppInitData();

const textColor = computed(() => props.banner?.promoOfferPreset?.bannerTextColor || "var(--cannes)");

const handleBannerClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInit.value?.depositsCount ?? 0
	});
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", props.banner?.promoOfferPreset?.id);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.banner?.promoOfferPreset?.id}/promoOffer/`);
};

const splitMoneyValue = computed(() => {
	const splittedArray = `${props.banner?.money}`.split(".");
	return {
		main: splittedArray[0],
		decimal: splittedArray[1]
	};
});
</script>

<template>
	<MBanner
		class="offer"
		:image="banner?.promoOfferPreset?.image2x"
		:image-mobile="banner?.promoOfferPreset?.imageMobile"
		:second-image="banner?.promoOfferPreset?.secondImage2x"
		:second-image-mobile="banner?.promoOfferPreset?.secondImageMobile"
		:bg-img="banner?.promoOfferPreset?.imageBanner"
		:bg-img2x="banner?.promoOfferPreset?.imageBannerMobile"
		type="bannerPresetPackage"
		@click="handleBannerClick"
	>
		<template #default>
			<AText class="title" variant="takoradi" :modifiers="['medium']">
				<span v-if="banner?.promoOfferPreset?.title" v-html="banner.promoOfferPreset.title" />
			</AText>
		</template>
		<template #description>
			<div class="info">
				<AText
					v-if="banner?.promoOfferPreset?.coins"
					variant="tlaquepaque"
					:modifiers="['bold']"
					class="money text-tlalnepantla"
				>
					{{ t("GET") }} <span class="text-coyoacan">{{ numberFormat(banner?.promoOfferPreset?.coins) }}</span>
					{{ t("FUN COINS") }}
				</AText>
				<AText
					v-if="banner?.promoOfferPreset?.entries"
					variant="tlaquepaque"
					:modifiers="['bold', 'uppercase']"
					class="money text-tlalnepantla"
				>
					<span class="text-сirebon">+ {{ numberFormat(banner?.promoOfferPreset?.entries) }}</span>
					{{ t("FREE CITY COINS!") }}
				</AText>
			</div>
		</template>
		<template #actions>
			<div class="actions">
				<AButton variant="primary" size="xl" class="button">
					<AText variant="tuzla" :modifiers="['semibold']" class="text-tlalnepantla">
						{{ t("Buy for") }} {{ appInit?.currencySymbol }}{{ splitMoneyValue?.main
						}}<span class="value-decimal">{{ splitMoneyValue?.decimal }}</span>
					</AText>
				</AButton>
			</div>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.value-decimal {
	position: relative;
	font-size: 14px;
	vertical-align: super;
	top: 0;
	left: 2px;

	@include media-breakpoint-down(md) {
		font-size: 11px;
	}
}
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: gutter(4);

			@include media-breakpoint-down(md) {
				padding: gutter(3) gutter(2);
			}
		}
		&__desc {
			margin-top: gutter(0.5);
		}
	}
}

.title {
	line-height: 28px;
}

.info {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);
	color: v-bind(textColor);
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	@include media-breakpoint-down(md) {
		gap: 0;
	}
}

.money {
	display: flex;
	align-items: center;
	gap: gutter(1);
	height: 38px;

	&:first-of-type {
		margin-bottom: gutter(1);

		@include media-breakpoint-down(md) {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		margin-top: 0;
		height: 33px;
		justify-content: center;
	}

	@media (max-width: 360px) {
		font-size: 20px;
	}
}

.actions {
	width: 280px;
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	align-items: center;

	@include media-breakpoint-down(md) {
		width: auto;
		margin-top: 0;
	}

	.button {
		width: 100%;

		@include media-breakpoint-down(md) {
			height: 40px;
			border-radius: 8px;
			margin-top: 0;
		}
	}
}

.price {
	display: flex;
	align-items: center;
	color: v-bind(textColor);
}
</style>
